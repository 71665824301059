<template>
  <section class="not-found">
    <header>
      <h1>Nothing here :(</h1>
    </header>
  </section>
</template>

<style lang="scss" scoped>
.not-found {
  width: 100%;
  header {
    width: 100%;
    padding: 1rem 2rem;
    border-bottom: 1px solid var(--theme-highlight);
    box-sizing: border-box;
  }

  h1 {
    color: var(--theme-on-surface);
    font-size: 1.5rem;
    font-weight: 700;
  }
}
</style>
